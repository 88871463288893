<template>
  <content-wrapper :title="labels.LBL_SYSTEM_ALBUM" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('albumForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_SAVE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      ref="albumForm"
      :model="album"
      :rules="rules"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="name" :label="labels.LBL_NAME">
            <el-input
              v-model="album.name"
              :placeholder="labels.PH_NAME"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item :label="labels.LBL_PUBLISH_ALBUM">
            <el-switch v-model="album.is_published"></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="16">
          <el-form-item prop="description" :label="labels.LBL_DESCRIPTION">
            <el-input
              v-model="album.description"
              type="textarea"
              :rows="4"
              :placeholder="labels.PH_DESCRIPTION"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="labels.LBL_IMAGES">
            <multi-image-editor :images="images" @onImageAdd="handleAddImage" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item :label="labels.LBL_VIDEOS">
            <multi-video-editor :videos="videos" @onVideoAdd="handleAddVideo" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import Album from "@/models/album";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import MultiImageEditor from "@/components/project-albums/MultiImageEditor";
  import MultiVideoEditor from "@/components/project-albums/MultiVideoEditor";
  import { mapState } from "vuex";

  export default {
    name: "AddProject",

    components: {
      ContentWrapper,
      MultiImageEditor,
      MultiVideoEditor,
    },

    data() {
      return {
        labels,
        rules,
        album: new Album(),
        images: [],
        videos: [],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        // systemTypes: (state) => state.album.systemTypes,
      }),

      // systemUnit() {
      //   return (
      //     this.album &&
      //     this.album.system_type_id &&
      //     this.systemTypes.find((s) => s.id === this.album.system_type_id).unit
      //   );
      // },
    },

    created() {
      // this.loadSystemType();
    },

    methods: {
      // loadSystemType() {
      //   this.$store.dispatch("album/getSystemTypes");
      // },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.album.album_medias = [...this.images, ...this.videos];
            if (!(this.album.album_medias.length > 0)) {
              this.$message.error("Please add images or video in the album");
            } else {
              this.$store.dispatch("album/createAlbum", this.album);
            }
          }
        });
      },

      handleAddImage(images) {
        this.images = images;
      },

      handleAddVideo(videos) {
        this.videos = videos;
      },
    },
  };
</script>

<style></style>
